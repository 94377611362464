import React from "react";
import PropTypes from "prop-types";
import TrackingHelper, { trackingEvents } from "../../lib/TrackingHelper";
import OtpV2 from "./otp-v2/OtpV2";
import V2ArrowRightIcon from "../../icons/V2ArrowRightIcon";
import V2WarningIcon from "../../icons/V2WarningIcon";

const LoginFormV2 = ({
  email,
  errors,
  handleOtpChange,
  onEmailChange,
  onSubmit,
  onOtpSubmit,
  otp,
  otpError,
  requestOtp,
  showOtp,
  showOtpRequest,
  text,
  supportEmail,
  supportPhone,
  supportContact,
}) => {
  return (
    <form className="login-form-v2" method="POST" onSubmit={onSubmit} noValidate>
      {text && <p className="login-container__text">{text}</p>}
      {!showOtp && (
        <div className="login-form-v2__group">
          <label className="login-form-v2__label" htmlFor="email">Email</label>
          <div className="login-form-v2__element-group">
            <input
              id="email"
              className={`login-form-v2__input form-input tracker-email ${errors.length ? "login-form-v2__input__error" : ""}`}
              data-validate="required email"
              data-test="email-input"
              type="email"
              name="user[email]"
              value={email}
              onChange={onEmailChange}
            />
            {errors.length > 0 && (
              <div className="login-form-v2__validation-message-wrapper">
                <div className="login-form-v2__validation-message-icon">
                  <V2WarningIcon />
                </div>
                <p className="login-form-v2__validation-message-text">{errors[0]}</p>
              </div>
            )}
          </div>
        </div>
      )}
      {(showOtp || showOtpRequest) && (
        <OtpV2
          error={otpError}
          onChange={handleOtpChange}
          onSubmit={onOtpSubmit}
          requestOtp={requestOtp}
          value={otp}
          supportEmail={supportEmail}
          supportPhone={supportPhone}
          supportContact={supportContact}
        />
      )}
      {!showOtp && (
        <div className={"login-form-v2__submit-container"}>
          <button
            type="submit"
            name="commit"
            data-test="submit-input"
            data-tw-page="Login Page"
            data-tw-label="Send code"
            className="login-form-v2__submit tracker-identify"
          >
            <div className="login-form-v2__submit-div">
              Send code
              <div className="login-form-v2__submit-icon">
                <V2ArrowRightIcon />
              </div>
            </div>
          </button>
        </div>
      )}
    </form>
  );
};

LoginFormV2.propTypes = {
  email: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  handleOtpChange: PropTypes.func.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  onOtpSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  otp: PropTypes.string,
  otpError: PropTypes.string,
  requestOtp: PropTypes.func.isRequired,
  showOtp: PropTypes.bool,
  showOtpRequest: PropTypes.bool,
  text: PropTypes.string,
  supportEmail: PropTypes.string,
  supportPhone: PropTypes.string,
  supportContact: PropTypes.string.isRequired,
};

export default LoginFormV2;
