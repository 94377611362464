import React from 'react';
import ReactDOM from 'react-dom';

const ReactDOMRender = (domId, Component) => {
  document.addEventListener('DOMContentLoaded', () => {
    const element = document.getElementById(domId);
    if (!element) return false;
    const props = JSON.parse(element.dataset.props || '{}');
    ReactDOM.render(React.createElement(Component, props, null), element);
  });
};

const renderIn = (domId, Component) => {
  ReactDOMRender(domId, Component);
  return Component;
};
export { renderIn };
