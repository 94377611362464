import React from "react";
import PropTypes from "prop-types";

const LoaderV2 = ({ title, description }) => (
  <div className="spinner-v2">
    <div className="spinner-v2__asset"></div>
    {title && <div className="spinner-v2__title-wrapper">{title}</div> }
    {description && <div className="spinner-v2__subtitle-wrapper">{description}</div> }
  </div>
);

LoaderV2.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default LoaderV2;
