import axios from "axios";
import { polyfill } from "es6-promise";

const SIGN_IN_URL = `/login`;
const SIGN_IN_OTP_URL = `/otp_login`;
const REGISTER_URL = "/users";

polyfill();

export class UserService {
  constructor({ baseURL = "", ...rest }) {
    this.axios = axios.create({ baseURL, ...rest });
  }

  loginUser({ email, password, rememberMe }) {
    return this.axios.post(`${SIGN_IN_URL}.json`, {
      user: {
        email,
        password,
        remember_me: rememberMe,
      },
    });
  }

  registerUserWithoutPassword(email) {
    return this.axios.post(
      `${REGISTER_URL}`,
      { user: { email } },
      { headers: { accept: "application/json" } }
    );
  }

  requestOtp(email) {
    return this.axios.post(
      `${REGISTER_URL}/one_time_password`,
      { email },
      { headers: { accept: "application/json" } }
    );
  }

  loginOtp({ email, otp }) {
    return this.axios.post(
      `${SIGN_IN_OTP_URL}`,
      { user: { email, one_time_password: otp } },
      { headers: { accept: "application/json" } }
    );
  }
}
