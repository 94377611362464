import React from 'react';
import PropTypes from 'prop-types';

const InfoListItem = ({ text }) => (
  <li className="info-list__item">
    <span className="material-icons info-list__item-icon">check</span>
    <div className="info-list__item-text">{text}</div>
  </li>
);

const WhyCoverwallet = ({ authorDescription, whyCoverWalletText }) => {
  return (
    <div className="info-section">
      <h2 className="info-section__title">
        {authorDescription}
      </h2>
      <ul className="info-list">
        {whyCoverWalletText.map((text) => (
          <InfoListItem key={text} text={text} />
        ))}
      </ul>
    </div>
  );
};

WhyCoverwallet.propTypes = {
  authorDescription: PropTypes.string.isRequired,
  whyCoverWalletText: PropTypes.array.isRequired,
};

export default WhyCoverwallet;
