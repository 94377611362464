import TrackingWallet from '@coverwallet/tracking-wallet';
import { getAppConfig } from '../utils/helpers/configHelper';

const trackingWalletConfig = {
  commonProps: {
    app: 'IdentityProvider',
    applicationMode: window.applicationModeName,
  },
  experiment: {
    amplitudeDeploymentKey: window.amplitudeKey
  }
}

const tracker = new TrackingWallet(trackingWalletConfig);

window.trackingWallet = tracker;

export function initTrackingWallet() {
  const isImpersonation = !!(window.App && window.App.isImpersonation);
  const { name, config } = getAppConfig();
  const { currentUserEmail, userRole, applicationMode } = config;

  const userId = currentUserEmail && !isImpersonation ? currentUserEmail : null;

  if (window && window.analytics && window.trackingWallet) {
    window.trackingWallet.identify(userId, {
      applicationMode,
      userRole,
      App: name,
    });
  }
}