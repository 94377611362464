import meanBy from 'lodash/meanBy';
import uniq from 'lodash/uniq';

export const trackingEvents = {
  BUTTON_CLICKED: 'Button Clicked',
  USER_LOGGED_IN: 'User Logged In',
  OTP_SENT: 'OTP Sent',
  OTP_SENDING_FAILED: 'OTP Sending Failed',
  OTP_VALIDATION_RESULT: 'OTP Validation Result',
  LOADED_A_NOTIFICATION: 'Loaded A Notification',
};

const getAbsoluteUrl = (relativeUrl) => {
    const link = document.createElement('a');
    link.href = relativeUrl;
    const absoluteUrl = link.href;

    return absoluteUrl;
};

const getAveragePremium = (items) => {
  if (items.length === 0) {
    return null;
  }

  return meanBy(items, (i) => i.premium) / 100.0;
};

const getRequestedLOBs = (business = {}) => {
  const quotes = business.quotes || [];

  return uniq(quotes.map((q) => q.insurance_type));
};

export default class TrackingHelper {
  static track(eventName, eventProps = {}, business) {
    const trackingEventProps = { ...eventProps };
    const { to: link } = eventProps;
    if (link || link === '') {
      trackingEventProps = {
        ...eventProps,
        to: getAbsoluteUrl(link),
      };
    }

    const businessProps = business
      ? TrackingHelper.getBusinessProps(business)
      : {};

    window.trackingWallet.track(eventName, {
      ...trackingEventProps,
      ...businessProps,
    });
  }

  static alias(userId) {
    window.trackingWallet.alias(`${userId}`.toLowerCase());
  }

  static identifyWithTraits(userId, traits = {}, options = {}) {
    if (
      !window.App.isImpersonation
    ) {
      const traitsWithWindowProperties = {
        ...traits,
        userRole: window.App.config.userRole,
        applicationMode: window.App.config.applicationMode
      };
      
      window.trackingWallet.identify(
        userId,
        traitsWithWindowProperties,
        options
      );
    }
  }

  static page() {
    window.trackingWallet.page();
  }

  static getBusinessProps(business) {
    const locations = business.locations || [];
    const quotes = business.quotes || [];
    const policies = business.policies || [];
    const businessLocations = locations.map((location) => {
      const { city, state } = location || {};
      return [city, state].filter((e) => e).join(', ');
    });

    return {
      annualRevenue: Number(business.annual_revenue),
      businessType: business.business_type,
      subClassCode: business.class_code_ref,
      subClassCodeDescription: business.sub_class_code_description,
      quoteLOBs: getRequestedLOBs(business),
      averageQuotePremium: getAveragePremium(quotes),
      policyLOBs: policies.map((p) => p.insurance_type),
      averagePolicyPremium: getAveragePremium(policies),
      businessLocationPrimary: businessLocations[0],
      businessLocations,
      yearsExperience: business.years_experience,
      yearBusinessStarted: business.years_business_started,
      customerEmail: business.contact_email,
      customerBusinessName: business.name,
      subindustry: business.subindustry,
    };
  }

  static isFlagOn(flagId) {
    return window.trackingWallet.getFlagVariant(flagId);
  }
}
