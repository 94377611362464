import React from "react";
import PropTypes from "prop-types";

const Loader = ({ title, description }) => (
  <div className="loader">
    <div className="spinner">
      <div className="spinner__animation">
        <div className="spinner__animation__inner"></div>
      </div>
    </div>
    {(title || description) && (
      <div className="loader__content">
        <div className="loader__title">{title}</div>
        <div className="loader__description">{description}</div>
      </div>
    )}
  </div>
);

Loader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Loader;
