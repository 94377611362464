import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Otp from "./otp/Otp";

const LoginForm = ({
  email,
  errors,
  handleOtpChange,
  isOtpEnabled,
  onEmailChange,
  onPasswordChange,
  onSubmit,
  otp,
  otpError,
  password,
  requestOtp,
  showOtp,
  showOtpRequest,
  showPassword,
  toggleLoginMethod,
  text,
  supportEmail,
  supportPhone,
  supportContact,
}) => {
  const submitText = showPassword ? "Log In »" : "Continue";
  const switchLoginText = showPassword ? "Use one-time code" : "Use password";
  const showEmailInput = !showPassword && !showOtp;
  const showLoginSwitch = showPassword || showOtpRequest;

  return (
    <form className="login-form" method="POST" onSubmit={onSubmit} noValidate>
      {text && <p className="login-container__text">{text}</p>}
      {showEmailInput && (
        <div className="login-form__group form-group">
          <label className="login-form__label" htmlFor="email">
            Email address
          </label>
          <input
            id="email"
            className="login-form__input form-input form-input--long tracker-email"
            data-validate="required email"
            data-test="email-input"
            type="email"
            name="user[email]"
            placeholder="Enter email address"
            value={email}
            onChange={onEmailChange}
          />
        </div>
      )}
      {showPassword && (
        <>
          <div className="login-form__group form-group">
            <label className="login-form__label" htmlFor="password">
              Password
            </label>
            <input id="email" type="hidden" name="user[email]" value={email} />
            <input
              id="password"
              className="login-form__input form-input form-input--long"
              data-validate="required password"
              data-test="password-input"
              name="user[password]"
              value={password}
              type="password"
              onChange={onPasswordChange}
            />
          </div>
        </>
      )}
      {(showEmailInput || showPassword) && (
        <div className="devise-errors">
          <ul className="devise-errors__list">
            {errors.map((error, index) => (
              <li key={`error-${index}`} data-test="error-container">
                {error}
              </li>
            ))}
          </ul>
        </div>
      )}
      {(showOtp || showOtpRequest) && (
        <Otp
          error={otpError}
          onChange={handleOtpChange}
          requestOtp={requestOtp}
          showOtpRequest={showOtpRequest}
          value={otp}
          supportEmail={supportEmail}
          supportPhone={supportPhone}
          supportContact={supportContact}
        />
      )}
      {!showOtp && (
        <div
          className={classNames("login-form__submit-container", {
            "login-form__submit-container--continue": !showPassword,
          })}
        >
          <input
            type="submit"
            name="commit"
            data-test="submit-input"
            data-tw-page="Login Page"
            data-tw-label="Continue"
            value={submitText}
            className="btn login-form__submit tracker-identify"
          />
        </div>
      )}

      {isOtpEnabled && showLoginSwitch && (
        <a onClick={toggleLoginMethod} className="login-form__switch-login">
          {switchLoginText}
        </a>
      )}
    </form>
  );
};

LoginForm.propTypes = {
  email: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  handleOtpChange: PropTypes.func.isRequired,
  isOtpEnabled: PropTypes.bool,
  onEmailChange: PropTypes.func.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  otp: PropTypes.string,
  otpError: PropTypes.string,
  password: PropTypes.string,
  requestOtp: PropTypes.func.isRequired,
  showOtp: PropTypes.bool,
  showOtpRequest: PropTypes.bool,
  showPassword: PropTypes.bool,
  text: PropTypes.string,
  supportEmail: PropTypes.string,
  supportPhone: PropTypes.string,
  supportContact: PropTypes.string.isRequired,
};

export default LoginForm;
