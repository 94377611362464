import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import OtpInput from "react-otp-input";

import TrackingHelper, { trackingEvents } from "../../../lib/TrackingHelper";
import OtpContactSupport from "./OtpContactSupport";

const RESEND_TIMEOUT = 10_000;
const MAX_RESEND_ATTEMPTS = 2;

const Otp = ({
  error,
  onChange,
  requestOtp,
  showOtpRequest,
  value,
  supportEmail,
  supportPhone,
  supportContact,
}) => {
  const [canResendCode, setCanResendCode] = useState(false);
  const [resendAttempts, setResendAttempts] = useState(0);
  const isDisabled = value?.length === 6;
  const isMaxResendAttempts = resendAttempts === MAX_RESEND_ATTEMPTS;

  useEffect(() => {
    let timer;
    if (!canResendCode) {
      timer = setTimeout(() => setCanResendCode(true), RESEND_TIMEOUT);
    }

    return () => clearTimeout(timer);
  }, [canResendCode]);

  const handleResendOtp = () => {
    TrackingHelper.track(trackingEvents.BUTTON_CLICKED, {
      buttonName: "request_otp_again",
      buttonType: "click",
      label: "Send code again",
    });

    if (canResendCode) {
      requestOtp({ hideLoader: true });
      setCanResendCode(false);
      setResendAttempts(resendAttempts + 1);
    }
  };

  const handleRequestOtp = () => {
    TrackingHelper.track(trackingEvents.BUTTON_CLICKED, {
      label: "Request Otp",
      buttonName: "request_otp",
      buttonType: "click",
      url: window.location.href,
    });

    requestOtp({ hideLoader: false });
  };

  if (showOtpRequest) {
    return (
      <div className="otp__request">
        <p>To continue, request a one-time passcode.</p>
        <p className="otp__request__reminder">
          The code will be sent to your email
        </p>
        <button onClick={handleRequestOtp} className="otp__request__btn">
          Send me the code »
        </button>
      </div>
    );
  }

  return (
    <div className="otp">
      <label htmlFor="otp-input">Please enter the code you received</label>
      <OtpInput
        containerStyle={"otp__container"}
        id="otp-input"
        inputStyle={"otp__input"}
        numInputs={6}
        onChange={onChange}
        renderInput={(props) => (
          <input {...props} style={{}} disabled={isDisabled} />
        )}
        shouldAutoFocus={true}
        value={value}
      />
      {isDisabled && (
        <span className="otp__validate-notification">Validating code...</span>
      )}
      {error && (
        <div className="devise-errors devise-errors__otp-error">{error}</div>
      )}
      <div className="otp__info">
        <OtpContactSupport
          supportEmail={supportEmail}
          supportPhone={supportPhone}
          supportContact={supportContact}
        />
        {!isMaxResendAttempts && (
          <p>
            You didn't receive the code? Try with{" "}
            <a
              href="#"
              onClick={handleResendOtp}
              className={cs("otp__info__resend-link", {
                "otp__info__resend-link--disabled": !canResendCode,
              })}
            >
              Resend code
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

Otp.propTypes = {
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  requestOtp: PropTypes.func.isRequired,
  showOtpRequest: PropTypes.bool,
  value: PropTypes.string,
  supportEmail: PropTypes.string,
  supportPhone: PropTypes.string,
  supportContact: PropTypes.string.isRequired,
};

export default Otp;
