import { validatePassword } from "./validations";

function verifyPassword(event) {
    let password = document.querySelector("#set-password__input").value;
    let errors = validatePassword(password);
    if (errors.length) {
        event.preventDefault();
        let hint = document.querySelector('#set-password__help-text');
        hint.textContent = errors.join("\n");
        hint.className = hint.className.replace('login-container__text--hint', 'login-container__text--errors');
    }
}

window.onload=function(){
    let submit_set_password = document.querySelector('#set-password__submit');
    if(submit_set_password) {
        submit_set_password.addEventListener("click", verifyPassword);
    }
};

